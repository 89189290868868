import cx from 'classnames';

import { Event, trackEvent } from '~/ui/components/analytics';
import { type Quote as QuoteType } from '~/v1/_types/Quote';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import { Image } from '~/v1/components/image/image';
import { Link } from '~/v1/components/link/link';

import styles from './quote.module.scss';
import { withLineBreaks } from './quote.utils';
import { AudioPlayer } from '../audioPlayer/audioPlayer';
import { ImageBreakpoint } from '../image/image.interface';

type QuoteProps = {
  quote: QuoteType;
  isNarrow?: boolean;
  classNames?: string;
};

export const Quote: React.FC<QuoteProps> = ({ quote, classNames, isNarrow, ...props }) => {
  const {
    quoteImage,
    audio,
    text,
    quoteLink: link,
    linkText,
    attribution,
    twitterUsername,
    isTwitterImage,
  } = quote;

  return (
    <div
      className={cx(styles.quote, classNames, {
        [styles.twitter]: isTwitterImage,
      })}
      {...props}
    >
      <div className={styles.quoteTopWrapper}>
        {quoteImage && quoteImage.src && (
          <div
            className={cx(styles.image, {
              [styles.imageNarrow]: isNarrow,
            })}
          >
            <Image
              src={quoteImage.src}
              alt={quoteImage.title}
              aspectRatio="4/5"
              colSpan={{ [ImageBreakpoint.SM]: 1 }}
            />
          </div>
        )}
        <div className="bodySmallTypography">
          {withLineBreaks(attribution)}
          {isTwitterImage && (
            <Link
              href={link ?? `https://twitter.com/${twitterUsername}`}
              className={styles.username}
              withIcon={IconType.Twitter}
            >
              @{twitterUsername}
            </Link>
          )}
        </div>
      </div>
      <div>
        {audio?.src && (
          <AudioPlayer
            componentNameAriaLabel="quote"
            src={audio.src}
            className={styles.listenButton}
            onPlay={() =>
              trackEvent(Event.QUOTE_AUDIO_PLAY, {
                quoteLink: link,
                quoteCopy: text,
              })
            }
            onPause={() =>
              trackEvent(Event.QUOTE_AUDIO_PAUSE, {
                quoteLink: link,
                quoteCopy: text,
              })
            }
          />
        )}
        <p className={cx('blockquoteTypography', styles.quoteText)}>“{text}”</p>
      </div>
      {!isTwitterImage && link && linkText && (
        <Link href={link} className={styles.link} withIcon>
          {linkText}
        </Link>
      )}
    </div>
  );
};
